import React, { useState, useRef, useEffect } from "react";

const HoverDropdown = ({ title, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    const containerElem = containerRef.current;

    // Open the dropdown on mouse enter
    const handleMouseEnter = () => {
      setIsOpen(true);
    };

    // Close the dropdown on mouse leave
    const handleMouseLeave = () => {
      setIsOpen(false);
    };

    containerElem.addEventListener("mouseenter", handleMouseEnter);
    containerElem.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      containerElem.removeEventListener("mouseenter", handleMouseEnter);
      containerElem.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <div ref={containerRef} className="relative inline-block">
      {title === "Register" ? (
        <button
          className={`${
            document.location.pathname === "/guardian-register" ||
            document.location.pathname === "institution-register"
              ? "text-yellow100"
              : "text-black"
          } text-sm py-3 rounded-[5px] w-36`}
        >
          {title}
        </button>
      ) : (
        <img src={title} alt="user" />
      )}
      {isOpen && (
        <div
          className={`${
            title === "Register"
              ? "left-0 w-36 bg-white"
              : "right-0 w-56 register-bg"
          } absolute shadow-md rounded-[5px] overflow-hidden z-50`}
        >
          {options?.map((item, index) => (
            <a
              key={index}
              href={item.path}
              className={`${
                title === "Register"
                  ? "text-gray-700 mt-1 hover:bg-yellow100 hover:text-white"
                  : "text-white hover:bg-white hover:text-gray-700"
              } block px-4 py-3 text-sm`}
            >
              {item.icon ? (
                <img src={item.icon} className="inline-block mr-5" alt="icon" />
              ) : null}
              {item.title}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default HoverDropdown;
