import React from "react";
import { ArrowLeft } from "../../assets";
import { useNavigate } from "react-router-dom";

const CommingSoon = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div
      className="h-[62vh] flex justify-center items-center"
      style={{ background: "rgba(249, 246, 239, 1)" }}
    >
      <button
        className="flex items-center mr-5 text-sm text-gray-500"
        onClick={handleGoBack}
      >
        <img src={ArrowLeft} className="mr-1" alt="go back" />
        Go Back
      </button>
      <p className="text-2xl">Coming Soon...</p>
    </div>
  );
};

export default CommingSoon;
