import React from "react";
import { Button, Navbar } from "flowbite-react";
import { MainLogo } from "../../assets";
import HoverDropdown from "../HoverDropdown";

const RegisterOptions = [
  { path: "/guardian-register", title: "Guardian" },
  { path: "/institution-register", title: "Institution" },
];

const Header = () => {
  return (
    <Navbar fluid className="shadow-md font-roboto ">
      <Navbar.Brand href="/" className="">
        <img src={MainLogo} alt="Logo" />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="pr-5">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-start md:justify-between">
          <Navbar.Link
            href="/"
            className={`${
              document.location.pathname === "/" ? "text-yellow100" : ""
            } text-sm  mx-2 lg:mx-6 hover:text-yellow100 `}
          >
            Home
          </Navbar.Link>
          <Navbar.Link
            href="/about-us"
            className={`${
              document.location.pathname === "/about-us" ? "text-yellow100" : ""
            } text-sm  mx-2 lg:mx-6 hover:text-yellow100`}
          >
            About Us
          </Navbar.Link>
          <Navbar.Link
            href="/login"
            className={`${
              document.location.pathname === "/login" ? "hidden" : "visible"
            }  text-sm mx-2 lg:mx-6`}
          >
            <Button className="text-black text-sm rounded-[5px]">Login</Button>
          </Navbar.Link>
          <HoverDropdown title="Register" options={RegisterOptions} />
          <Navbar.Link href="/contact-sales" className="text-sm  mx-2 lg:mx-6">
            <Button
              className={`${
                document.location.pathname === "/contact-sales"
                  ? "text-yellow100"
                  : "text-black"
              } text-sm rounded-[5px]`}
            >
              Contact Sales
            </Button>
          </Navbar.Link>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
