import { Navbar } from "flowbite-react";
import React from "react";
import {
  contactIcon,
  infoIcon,
  logoutIcon,
  MainLogo,
  profileIcon,
  reportIcon,
  sessionIcon,
  userIcon,
} from "../../assets";
import HoverDropdown from "../HoverDropdown";

const UserOptions = [
  { icon: profileIcon, title: "Ramsha" },
  { icon: sessionIcon, title: "Start session" },
  { icon: infoIcon, title: "Instructions" },
  { icon: reportIcon, title: "Report" },
  { icon: contactIcon, title: "Contact professional" },
  { icon: logoutIcon, title: "Logout" },
];

const DashNavbar = () => {
  return (
    <Navbar fluid className="shadow-md font-roboto ">
      <Navbar.Brand href="/" className="">
        <img src={MainLogo} alt="Logo" />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="pr-5">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-start md:justify-between">
          <Navbar.Link
            href="/about-us"
            className={`${
              document.location.pathname === "/about-us" ? "text-yellow100" : ""
            } text-sm  lg:mx-6 hover:text-yellow100`}
          >
            About Us
          </Navbar.Link>
          <HoverDropdown title={userIcon} options={UserOptions} />
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default DashNavbar;
