import { Carousel } from "flowbite-react";
import React from "react";
import {
  AwardImg,
  VectorSvg,
  SponsorImg1,
  SponsorImg2,
  AwardImg2,
  AwardImg1,
  AwardImg3,
  AwardImg4,
  AwardImg5,
} from "../../assets";
import { CustomAccordion, TeamCard } from "../../components";
import { Awards, TeamMembers } from "../../Data";

const images = [SponsorImg1, SponsorImg2];

const AwardImgs = [
  AwardImg,
  AwardImg1,
  AwardImg2,
  AwardImg3,
  AwardImg4,
  AwardImg5,
];

const AboutUs = () => {
  return (
    <div>
      <div className="h-96 xl:h-[50rem] 2xl:h-[50rem] no-border-radius">
        <Carousel leftControl=" " rightControl=" " className="no-border-radius">
          <div className="carousel-item h-full flex items-end no-border-radius">
            <p className="text-center w-12/12 md:w-10/12 lg:w-6/12 mx-auto mb-24 text-md lg:text-[32px] font-bold text-white">
              We serve caregivers (B2C), healthcare professionals and therapy
              robot manufacturers (B2B), providing a cutting-edge Brain-Computer
              Interface (BCI) feedback system.
            </p>
          </div>
          <div className="carousel-item2 h-full flex items-end no-border-radius">
            <p className="text-center w-12/12 md:w-10/12 lg:w-6/12 mx-auto mb-24 text-md lg:text-[32px] font-bold text-white">
              We serve caregivers (B2C), healthcare professionals and therapy
              robot manufacturers (B2B), providing a cutting-edge Brain-Computer
              Interface (BCI) feedback system.
            </p>
          </div>
        </Carousel>
      </div>
      <div
        style={{ background: "rgba(249, 246, 239, 1)" }}
        className="relative"
      >
        <img
          src={VectorSvg}
          className="absolute top-0 lg:top-[15%] z-0"
          alt="vector"
        />
        <div className="main-container py-10">
          <h2 className="text-xl md:text-4xl text-center main-heading font-bold my-3 pt-5">
            Team Members
          </h2>
          <p className="text-sm md:text-xl text-center">
            Our team members are ready to help our clients
          </p>
          <div className="grid grid-cols-12 gap-5 lg:gap-20 xl:gap-10 my-10">
            {TeamMembers?.map((item) => (
              <div className="col-span-12 lg:col-span-6 xl:col-span-3 z-10">
                <TeamCard
                  image={item.image}
                  name={item.name}
                  position={item.position}
                  LinkedInLink={item.LinkedInLink}
                />
              </div>
            ))}
          </div>
        </div>
        <img
          src={VectorSvg}
          className="absolute bottom-0 lg:top-[7%] right-0 rotate-180 z-0"
          alt="vector"
        />
      </div>
      <div className="main-container py-5 mt-5">
        <h2 className="text-xl md:text-4xl text-center main-heading font-bold my-8">
          About Team
        </h2>
        <div className="grid grid-cols-12 gap-7 w-full">
          {TeamMembers?.map((item) => (
            <div className="team-card">
              <div
                key={item.id}
                className="flex flex-col xl:flex-row justify-between items-start"
              >
                <div className="w-12/12 xl:w-4/12 relative mx-auto">
                  <img src={item.image} alt="team-member1" className="w-full" />
                  <div className="team-position">
                    <p className="text-center text-sm py-2">{item.position}</p>
                  </div>
                </div>
                <div className="description-section w-12/12 xl:w-8/12 ml-5 pr-2 py-5 lg:py-4 overflow-y-auto">
                  <p className="text-sm md:text-md leading-6">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="main-container my-20">
        <div className="grid grid-cols-12 gap-5 md:gap-10 ">
          <div className="col-span-12 lg:col-span-6">
            <CustomAccordion AwardsTitle="Awards won" Awards={Awards} />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <div className="h-72 md:h-[30rem] 2xl:h-[30rem] flex items-center">
              <Carousel pauseOnHover leftControl=" " rightControl=" ">
                {AwardImgs?.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    className="h-full w-full"
                    alt={`award-${index}`}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-xl md:text-4xl text-center main-heading font-bold my-12">
          The Startup is supported by
        </h2>
        <div className="flex justify-center items-center">
          {images?.map((image) => (
            <img src={image} alt="image" className="mx-10 mb-10" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
