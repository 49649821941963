import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { PauseIcon, StopIcon } from "../../assets";

const Stopwatch = () => {
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const totalSeconds = 0.5 * 60 * 60; // 30 min in seconds

  useEffect(() => {
    let timerId;

    if (isRunning && secondsElapsed < totalSeconds) {
      timerId = setInterval(() => {
        setSecondsElapsed((prev) => prev + 1);
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [isRunning, secondsElapsed, totalSeconds]);

  const percentage = (secondsElapsed / totalSeconds) * 100;

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  const handlePauseResume = () => {
    setIsRunning(!isRunning);
  };

  const handleQuit = () => {
    setIsRunning(false);
    setSecondsElapsed(0);
  };

  return (
    <div>
      <div
        style={{ width: 100, height: 100, margin: "auto", textAlign: "center" }}
      >
        <CircularProgressbar
          value={percentage}
          text={formatTime(secondsElapsed)}
          styles={buildStyles({
            textSize: "16px",
            pathColor: "#EDC31B",
            textColor: "#fff",
            trailColor: "#fff",
            backgroundColor: "#fff",
          })}
        />
      </div>
      <div className="mt-5 flex justify-around items-center">
        <button onClick={handlePauseResume}>
          {isRunning ? (
            <>
              <div className="w-10 h-10 rounded-full bg-yellow100 flex justify-end items-center overflow-hidden">
                <img src={PauseIcon} className="mx-auto" alt="icon" />
              </div>
              <p className="text-white">Pause</p>
            </>
          ) : (
            <>
              <div className="w-10 h-10 rounded-full bg-yellow100 flex justify-end items-center overflow-hidden">
                <img src={StopIcon} className="mx-auto" alt="icon" />
              </div>
              <p className="text-white">Play</p>
            </>
          )}
        </button>
        <button onClick={handleQuit}>
          <div className="w-10 h-10 rounded-full bg-yellow100 flex justify-end items-center overflow-hidden">
            <img src={StopIcon} className="mx-auto" alt="icon" />
          </div>
          <p className="text-white">Stop</p>
        </button>
      </div>
    </div>
  );
};

export default Stopwatch;
