import * as React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { DashNavbar, Footer, Header } from "./components";

function App() {
  return (
    <div>
      {document.location.pathname === "/dasboard" ? <DashNavbar /> : <Header />}
      <RouterProvider router={router} />
      <Footer />
    </div>
  );
}

export default App;
