import React from "react";
import { profileImg1 } from "../../assets";

const PatientInfo = () => {
  return (
    <div className="card-bg py-5 px-5 md:px-10 rounded-lg flex-wrap flex items-center min-h-40">
      <div className="w-20 md:w-28 h-20 md:h-28 rounded-full overflow-hidden flex justify-center mx-auto md:items-start">
        <img
          src={profileImg1}
          className="w-full h-full"
          alt="patient-profile"
        />
      </div>
      <div className="lg:ml-24">
        <p className="text-[15px] my-5">
          Name: <span className="text-white ml-10">Ramsha Minhaj</span>
        </p>
        <p className="text-[15px] my-5">
          Age: <span className="text-white ml-10">24</span>
        </p>
      </div>
    </div>
  );
};

export default PatientInfo;
