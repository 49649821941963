import React from "react";

const ProductCard = ({ icon, title }) => {
  return (
    <div className="bg-lightBlue rounded-md flex flex-col justify-center items-center h-80 border-b-[10px] border-yellow100">
      <div className="w-[90px] h-[90px] rounded-full bg-white flex justify-center items-center">
        <img src={icon} alt="icon" />
      </div>
      <p className="text-xl md:text-2xl text-white py-10 font-bold capitalize">
        {title}
      </p>
    </div>
  );
};

export default ProductCard;
