import { Accordion, List } from "flowbite-react";
import TeamCard from "../TeamCard";

const CustomAccordion = ({ items, Awards, AwardsTitle }) => {
  return (
    <div>
      {Awards ? (
        <Accordion.Panel isOpen={true}>
          <Accordion.Title className="accordion text-white">
            {AwardsTitle}
          </Accordion.Title>
          <Accordion.Content className="p-0">
            {Awards?.map((item, index) => (
              <List className="list-none" key={index}>
                <List.Item className="border-b px-3 md:px-5 py-4">
                  {item}
                </List.Item>
              </List>
            ))}
          </Accordion.Content>
        </Accordion.Panel>
      ) : (
        items?.map((item) => (
          <Accordion.Panel key={item.id} className="my-2" isOpen={true}>
            <Accordion.Title className="accordion text-white py-2">
              {item.position}
            </Accordion.Title>
            <Accordion.Content className="px-3 md:px-5 pt-5 pb-3 lg:pb-0 border">
              <div className="grid grid-cols-12 gap-5 lg:gap-8">
                <div className="col-span-12 md:col-span-4 lg:col-span-2">
                  <TeamCard
                    image={item.image}
                    name={item.name}
                    classNames="h-[11rem]"
                  />
                </div>
                <div className="col-span-12 md:col-span-8 lg:col-span-10">
                  <p className="text-sm lg:text-xl leading-7 lg:leading-9">
                    {item.description}
                  </p>
                </div>
              </div>
            </Accordion.Content>
          </Accordion.Panel>
        ))
      )}
    </div>
  );
};

export default CustomAccordion;
