import React, { useState, useEffect, useRef } from "react";

const ImageList = ({ initialImages, ImagesClassname, minWidth }) => {
  const [currentIndex, setCurrentIndex] = useState(3);
  const imageWidth = 384;
  const transitionRef = useRef();
  const numClones = 3;

  useEffect(() => {
    transitionRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 2000);

    return () => clearInterval(transitionRef.current);
  }, []);

  useEffect(() => {
    if (currentIndex >= initialImages.length + numClones) {
      setCurrentIndex(numClones);
    }
  }, [currentIndex, initialImages.length, numClones]);

  const extendedImages = [
    ...initialImages.slice(-numClones),
    ...initialImages,
    ...initialImages.slice(0, numClones),
  ];

  return (
    <div className="flex items-center justify-center pb-10 bg-white rounded-lg">
      <div className="overflow-hidden w-full relative">
        <div
          className="flex items-center transition-transform duration-300 ease-in-out"
          style={{
            width: `${extendedImages.length * imageWidth}px`,
            transform: `translateX(-${currentIndex * imageWidth}px)`,
            transition:
              currentIndex >= initialImages.length + numClones
                ? "none"
                : undefined,
          }}
        >
          {extendedImages.map((image, index) => (
            <img
              key={index}
              className={`${ImagesClassname} mx-2 object-cover rounded`}
              src={image}
              alt={`Slide ${index - numClones + 1}`}
              style={{ minWidth: minWidth }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageList;
