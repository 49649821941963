import { Button } from "flowbite-react";
import React from "react";
import { LinkedInIcon, MainLogo } from "../../assets";

const Footer = () => {
  return (
    <div className="bg-dark100">
      <div className="main-container">
        <div className="flex justify-between items-end flex-wrap py-8">
          <div>
            <p className="text-white text-center text-xs py-1 font-light">
              Connect with us we <br /> can help you
            </p>
            <a href="/contact-sales">
              <Button className="bg-yellow100 text-xs font-light">
                Contact Sales
              </Button>
            </a>
          </div>
          <div className="flex flex-col items-center">
            <a href="/">
              <img src={MainLogo} alt="logo" className="mx-auto" />
            </a>
            <p className="text-white text-xs py-1 font-bold">CogniCare</p>
            <p className="text-white text-xs font-light">
              Therapy at your doorstep
            </p>
            <a
              href="https://www.linkedin.com/company/ccareglobal/"
              target="_blank"
              className="text-white text-xs font-light underline"
            >
              Find us on Linkedin
            </a>
          </div>
          <div className="mx-auto md:mx-0 mt-5 md:mt-0">
            <p className="text-white text-xs font-light">
              We are on social media
            </p>
            <div className="flex justify-end items-center mt-2">
              <a
                href="https://www.linkedin.com/company/ccareglobal/"
                target="_blank"
              >
                <img src={LinkedInIcon} alt="logo" />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <p className="text-center text-white text-xs leading-8 py-3 font-light">
          © 2023 CogniCare Health Care
        </p>
      </div>
    </div>
  );
};

export default Footer;
