import { Button } from "flowbite-react";
import React from "react";
import Chart from "react-google-charts";
import { PatientInfo } from "../../components";
import {
  barChartData,
  chartData,
  chartOptions,
  pieChartData,
  pieChartOptions,
} from "../../Data";

const Dashboard = () => {
  return (
    <div className="register-bg px-5 md:px-10 py-6">
      <div className="bg-gray100 rounded-[5px] ">
        <div className="main-container py-8">
          <div className="grid grid-cols-12 gap-5 md:gap-14">
            <div className="col-span-12 md:col-span-6">
              <PatientInfo />
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="card-bg py-5 px-10 rounded-lg flex items-center flex-wrap justify-around h-40">
                <p className="text-[15px] my-5">
                  Date: <span className="text-white ml-10">24-04-2024</span>
                </p>
                <p className="text-[15px] my-5">
                  Time: <span className="text-white ml-10">10: 30 pm</span>
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 md:gap-8 my-5">
            <div className="col-span-12 md:col-span-4 rounded-lg shadow-lg overflow-hidden bg-white p-4">
              <Chart
                chartType="LineChart"
                width="100%"
                height="250px"
                data={chartData}
                options={chartOptions}
                legendToggle
              />
              <p className="text-center py-4">
                Patient ECG data analysis trend
              </p>
            </div>
            <div className="col-span-12 md:col-span-4 rounded-lg shadow-lg overflow-hidden bg-white p-4">
              <Chart
                chartType="PieChart"
                width="100%"
                height="250px"
                data={pieChartData}
                options={pieChartOptions}
                legendToggle
                style={{ backgroundColor: "#000" }}
              />
              <p className="text-center py-4">
                Patient ECG data analysis trend
              </p>
            </div>
            <div className="col-span-12 md:col-span-4 rounded-lg shadow-lg overflow-hidden bg-white p-4">
              <Chart
                chartType="Bar"
                width="100%"
                height="250px"
                data={barChartData}
              />
              <p className="text-center py-4">
                Patient ECG data analysis trend
              </p>
            </div>
          </div>
          <div className="flex justify-end items-center mt-8">
            <Button className="bg-yellow100 rounded-[5px]">Print Report</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
