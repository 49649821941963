import React, { useRef, useState, useEffect } from "react";

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Hide the button after 3 seconds if the video is playing
  useEffect(() => {
    let timer;
    if (isPlaying) {
      timer = setTimeout(() => {
        setShowButton(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [isPlaying]);

  const handleMouseEnter = () => {
    setShowButton(true);
  };

  const handleMouseLeave = () => {
    setShowButton(false);
  };

  return (
    <div
      style={{ position: "relative", width: "100%" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <video
        ref={videoRef}
        className="rounded-md"
        width="100%"
        onClick={togglePlayPause}
        style={{ display: "block" }} // Ensures no extra spacing around video
      >
        <source src={src} type="video/mp4" />
      </video>
      {showButton && (
        <button
          onClick={togglePlayPause}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "rgba(0, 0, 0, 0.5)",
            color: "white",
            border: "none",
            borderRadius: "50%",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isPlaying ? (
            // Pause Icon
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0ZM13.5 21H10.5V9H13.5V21ZM19.5 21H16.5V9H19.5V21Z"
                fill="#EAC551"
              />
            </svg>
          ) : (
            // Play Icon
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M25.6065 4.39395C19.749 -1.46465 10.251 -1.46465 4.3935 4.39395C-1.4645 10.2515 -1.4645 19.7492 4.3935 25.6068C10.251 31.4644 19.749 31.4644 25.6065 25.6068C31.4645 19.7492 31.4645 10.251 25.6065 4.39395ZM11.5 25.0063V4.99446L21.5 15.0001L11.5 25.0063Z"
                fill="#CCCCCC"
              />
            </svg>
          )}
        </button>
      )}
    </div>
  );
};

export default VideoPlayer;
