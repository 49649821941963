import { type } from "@testing-library/user-event/dist/type";
import {
  TeamMember1Img,
  TeamMember2Img,
  TeamMember3Img,
  TeamMember4Img,
} from "../assets";

export const TeamMembers = [
  {
    id: 1,
    name: "Ramsha Minhaj",
    image: TeamMember1Img,
    position: "Founder and Director",
    LinkedInLink: "https://www.linkedin.com/in/ramsha-minhaj/",
    description:
      "My journey with CogniCare began in my undergraduate final year when I self-proposed a project to create a Brain-Computer Interface (BCI) system for dementia patients. I saw the potential to make a real difference, and with the guidance and support of my supervisor, I achieved surprisingly good results. This success ignited my passion for the field and led me to pursue an MSc in Biomedical Engineering. I once again proposed my thesis to research new ways to treat dementia, collaborating with The Chinese University of Hong Kong's top neuroscience research team.",
  },
  {
    id: 2,
    name: "Namira Seraj",
    image: TeamMember2Img,
    position: "Operations Officer",
    LinkedInLink: "https://www.linkedin.com/in/namira-seraj/",
    description:
      "I joined CogniCare driven by a shared vision and a deep-rooted commitment to making a difference. The opportunity arrived out of the blue as Ramsha who is my childhood friend, required expertise on the administration and financial aspects for a pitch. With a Bachelor's in Finance and Accounting, and recognition as the top ACCA achiever in Bangladesh, I have always been passionate about turning ideas into successful ventures. So I ended up joining the team and now I am pursuing honours at Monash University with a research focused on Gerontechnology, so that my academic and professional journey aligns perfectly with CogniCare's mission.",
  },
  {
    id: 3,
    name: "Anosha Minhaj",
    image: TeamMember3Img,
    position: "Finance Officer",
    LinkedInLink: "https://www.linkedin.com/in/anosham/",
    description:
      "With years of experience in Audit and different startups, Anosha is a well rounded individual who is committed to making an impact. She has expert knowledge in finance, data analysis, entrepreneurship and is well versed in Hong Kong Companies’ Ordinance. With her expertise she is determined to see CogniCare flourish to it’s full potential.",
  },
  {
    id: 4,
    name: "Aaron Zhenyang Tao",
    image: TeamMember4Img,
    position: "AI Architect",
    LinkedInLink: "https://www.linkedin.com/in/aarontaozy/",
    description:
      "As a current MSc student in Signal Processing at Imperial College London, my expertise in artificial intelligence and technology innovation has been a driving force behind my contributions to CogniCare.  I am committed to leveraging my expertise to deliver groundbreaking advancements in the field, ensuring that CogniCare remains at the forefront of technological innovation.",
  },
];

export const Awards = [
  "Semi-Finalist in HK ICT Award 2023 hosted by Cyberport",
  "Best Pitching Team and $100K Award winner HKMU Metrochallenge 2023",
  "Excellence Award for being Top 5 in the Innovate For Future 2023",
  "3rd Position in the HK Innovation and Entrepreneurship Competition 2023 hosted by HKSTP",
  "HKSTP Ideation Seed Funding Winner",
  "Best FYP Project in HKMU Department of S&T class of 2023",
];

export const chartData = [
  [
    { type: "number", label: "x" },
    { type: "number", label: "values" },
    { id: "i0", type: "number", role: "interval" },
    { id: "i1", type: "number", role: "interval" },
    { id: "i2", type: "number", role: "interval" },
    { id: "i2", type: "number", role: "interval" },
    { id: "i2", type: "number", role: "interval" },
    { id: "i2", type: "number", role: "interval" },
  ],
  [1, 100, 90, 110, 85, 96, 104, 120],
  [2, 120, 95, 130, 90, 113, 124, 140],
  [3, 130, 105, 140, 100, 117, 133, 139],
  [4, 90, 85, 95, 85, 88, 92, 95],
  [5, 70, 74, 63, 67, 69, 70, 72],
  [6, 30, 39, 22, 21, 28, 34, 40],
  [7, 80, 77, 83, 70, 77, 85, 90],
  [8, 100, 90, 110, 85, 95, 102, 110],
];

export const chartOptions = {
  title: "Bars, default",
  curveType: "line",
  series: [{ color: "#000" }],
  intervals: { style: "dots" },
  legend: "none",
};

export const barChartData = [
  ["", "", "", ""],
  ["2014", 1000, 400, 200],
  ["2015", 1170, 460, 250],
  ["2016", 660, 1120, 300],
  ["2017", 1030, 540, 350],
];

export const pieChartData = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7], // CSS-style declaration
];

export const pieChartOptions = {
  // title: "My Daily Activities",
  pieHole: 0.4,
  is3D: false,
};
