import React from "react";
import { LinkedInImg } from "../../assets";

const TeamCard = ({ image, name, position, LinkedInLink }) => {
  return (
    <div className="w-full">
      <div className="w-full h-full">
        <img src={image} alt="team-member1" className="w-full h-full" />
      </div>
      <div
        className={`${
          position ? "-translate-y-12" : "-translate-y-8"
        } bg-white shadow-lg w-10/12 md:w-11/12 mx-auto border-t-[10px] border-yellow100 py-2`}
      >
        {position ? (
          <>
            <p className="text-center text-xs md:text-[15px] lg:text-sm font-semibold pb-2">
              {position}
            </p>
            <hr />
          </>
        ) : null}
        <div
          className={`${
            position
              ? "justify-between text-[15px] h-10"
              : "justify-center text-[16px]"
          } flex items-center flex-wrap px-4`}
        >
          <p className="text-start text-sm grow font-normal">{name}</p>
          {position ? (
            <div className="flex justify-end grow items-center">
              <a href={LinkedInLink} target="_blank">
                <img src={LinkedInImg} className="h-5 w-5" alt="linkedInImg" />
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
