import { createBrowserRouter } from "react-router-dom";
import {
  AboutUs,
  CommingSoon,
  ConnectionReset,
  ContactSales,
  Dashboard,
  GuardianRegister,
  Home,
  InstitutionRegister,
  Login,
} from "./pages";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/guardian-register",
    element: <CommingSoon />,
  },
  {
    path: "/institution-register",
    element: <CommingSoon />,
  },
  {
    path: "/login",
    element: <CommingSoon />,
  },
  {
    path: "/contact-sales",
    element: <CommingSoon />,
  },
  {
    path: "/dasboard",
    element: <Dashboard />,
  },
  {
    path: "/connection-reset",
    element: <CommingSoon />,
  },
]);
