import { Carousel } from "flowbite-react";
import React from "react";
import { ImageList, ProductCard, VideoPlayer } from "../../components";
import {
  AwardImg1,
  AwardImg2,
  AwardImg3,
  AwardImg4,
  AwardImg5,
  AwardVideo,
  DollarIcon,
  ScreenIcon,
  ThumbsUpIcon,
} from "../../assets";

const images = [
  AwardImg1,
  AwardImg2,
  AwardImg3,
  AwardImg4,
  AwardImg5,
  AwardImg1,
  AwardImg2,
  AwardImg3,
];

const Home = () => {
  return (
    <div className="font-roboto">
      <div
        className="h-96 xl:h-[50rem] 2xl:h-[50rem] no-border-radius"
        id="myCarousel"
      >
        <Carousel
          leftControl=" "
          rightControl=" "
          className="carousel-custom no-border-radius"
        >
          <div className="carousel-item h-full flex items-end no-border-radius">
            <p className="text-center w-12/12 md:w-10/12 lg:w-6/12 mx-auto mb-24 text-md lg:text-[32px] font-bold text-white">
              CogniCare is a dynamic health tech startup with a ground breaking
              mission to transform the way dementia patients receive care and
              therapy
            </p>
          </div>
          <div className="carousel-item2 h-full flex items-end no-border-radius">
            <p className="text-center w-12/12 md:w-10/12 lg:w-6/12 mx-auto mb-24 text-md lg:text-[32px] font-bold text-white">
              CogniCare is a dynamic health tech startup with a ground breaking
              mission to transform the way dementia patients receive care and
              therapy
            </p>
          </div>
        </Carousel>
      </div>

      <div className="main-container py-5 mt-3">
        <h2 className="text-xl md:text-4xl text-center main-heading font-bold my-8">
          Unlocking Minds, Improving Dementia Care
        </h2>
        <p className="text-sm md:text-[22px] leading-6 md:leading-9">
          CogniCare Limited is an innovative startup, founded in August 2023
          with support from the Hong Kong Science and Technology Park and Hong
          Kong Metropolitan University. Emerging from our founder’s final year
          bachelor's degree project, CogniCare is dedicated to transforming
          healthcare by enhancing its effectiveness and accessibility. Our
          primary focus is on advancing Cognitive Stimulation Therapy (CST) for
          dementia patients, aiming to streamline the therapy process and
          address common pain points.
        </p>
        <p className="text-sm md:text-[22px] leading-6 md:leading-9 mt-8">
          We serve both caregivers, healthcare professionals and therapy robot
          manufacturers, providing an innovative Brain-Computer Interface (BCI)
          feedback system. This system utilizes EEG headsets to delivers
          real-time emotional responses from patients during therapy sessions,
          enabling therapists, robots, or caregivers to adapt therapeutic
          activities based on accurate, immediate feedback. Additionally, our
          technology offers detailed reports on patients' emotional well-being
          over time, helping monitor the effectiveness of ongoing treatments. At
          CogniCare, we are committed to making a significant impact on dementia
          care by ensuring therapies are not only effective but also responsive
          to the unique needs of each patient.
        </p>
      </div>
      <div className="main-container">
        <h2 className="text-xl md:text-4xl text-center main-heading font-bold my-8">
          Product Benefits
        </h2>
        <p className="text-sm text-center md:text-[22px] leading-6 md:leading-9">
          Cognitive Stimulation Therapy (CST) is the most effective treatment
          for dementia, yet making it widely accessible remains a global
          challenge. CogniCare addresses this challenge by introducing a
          lightweight, inexpensive EEG Brain-Computer Interface (BCI) for more
          accurate feedback collection.
        </p>
      </div>
      <div className="w-11/12 lg:w-10/12 xl:w-7/12 mx-auto py-5 mt-3">
        <div className="grid grid-cols-12 gap-5 xl:gap-10 lg:gap-20 my-8">
          <div className="col-span-12 md:col-span-4 lg:mx-5">
            <ProductCard title="Affordability" icon={DollarIcon} />
          </div>
          <div className="col-span-12 md:col-span-4 lg:mx-5">
            <ProductCard title="Accuracy" icon={ScreenIcon} />
          </div>
          <div className="col-span-12 md:col-span-4 lg:mx-5">
            <ProductCard title="Satisfaction" icon={ThumbsUpIcon} />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5 py-8 items-center">
          <div className="col-span-12 md:col-span-5">
            <h3 className="text-xl lg:text-[22px] font-bold">
              CogniCare Healthcare Solution
            </h3>
            <p className="text-xl lg:text-[20px] xl:text-[22px] pt-4 leading-8 lg:leading-9 tracking-wider">
              CogniCare provides cost-effective solution improves the accuracy
              and effectiveness of CST, making it more accessible. CogniCare was
              featured in Hong Kong Commercial Daily, for winning the Best
              Pitching Team and receiving $100K at HKMU Metrochallenge 2023.
            </p>
          </div>
          <div className="col-span-12 md:col-span-7">
            <VideoPlayer src={AwardVideo} />
          </div>
        </div>
      </div>
      <div className="pb-5 md:pb-20">
        <h2 className="text-xl md:text-4xl text-center main-heading font-bold my-8">
          CogniCare Startup Achievements Awards
        </h2>
        <ImageList
          initialImages={images}
          ImagesClassname="w-[28rem] h-[19rem]"
          minWidth="28rem"
        />
      </div>
    </div>
  );
};

export default Home;
